<template>
  <div class="mailroom_receipt_wrapper">
    <section class="consignment_header">
      <i class="closeme adj1" @click="closeModal">X</i>
      <a class="bt_print" @click="printPage">
        <i class="material-icons">print</i>
        Print
      </a>
    </section>
    <section class="mailroom_receipt">
      <div class="barcode_div">
        <barcode id="barcode-image" :value="data.booking_prefix + data.booking_id" tag="img"/>
      </div>
      <div class="details_row">
        <div>RECEIPIENT:</div>
        <div>{{data.delivery_name}}</div>
      </div>
      <div class="details_row">
        <div>DELIVER TO:</div>
        <div>{{data.delivery_address_1}} , {{data.delivery_suburb}} , {{data.delivery_postal_code}} , {{data.delivery_state}} , {{data.delivery_country}}</div>
      </div>
      <div class="details_row">
        <div>PHONE:</div>
        <div>{{data.delivery_phone}}</div>
      </div>
      <div class="details_row">
        <div>PIECES:</div>
        <div>{{data.item_qty}}</div>
        <span style="margin-left: 70px;">
          <span v-if="data.delivery_desk"><strong>Desk#:</strong> {{data.delivery_desk}}</span>
          <span v-if="data.delivery_floor" style="margin-left: 10px;"><strong>Floor#:</strong> {{data.delivery_floor}}</span>
        </span>
      </div>
      <div class="details_row">
        <div>SEND BY:</div>
        <div>{{data.requester_name}}</div>
      </div>
      <template>
      <div v-if="data.secretary" class="details_row">
        <div>PA/EA:</div>
        <div>{{data.secretary.name}}</div>
        <span style="float:right;margin-left:20px;">
            <span v-if="data.secretary.desk"><strong>Desk#:</strong> {{data.secretary.desk}}</span>
            <span v-if="data.secretary.floor" style="margin-left: 10px;"><strong>Floor#:</strong> {{data.secretary.floor}}</span>
          </span>
        </div>
      </template>
    </section>
  </div>
</template>
<script>
import jQuery from 'jquery';
export default {
  name: "MailroomReceipt",
  methods: {
    closeModal() {
      this.modalStatus = !this.modalStatus;
      this.$emit("closeModal", this.modalStatus);
    },
    printPage() {
      var labelData = {
        'image': jQuery('#barcode-image').attr('src'),
        'barcode': this.data.booking_prefix + this.data.booking_id,
        'recipient': this.data.delivery_name,
        'deliver_to': this.data.delivery_address_1 +', '+  this.data.delivery_suburb +', '+ this.data.delivery_postal_code +', '+ this.data.delivery_state +', '+ this.data.delivery_country,
        'phone': this.data.delivery_phone,
        'pieces': this.data.item_qty,
        'deliver_by': this.data.requester_name,
        'desk': this.data.delivery_desk,
        'floor': this.data.delivery_floor,
        'secretary': '',
        'secretary_floor': '',
        'secretary_desk': '',
      };
      if (this.data.secretary) {
        labelData.secretary = this.data.secretary.name
        labelData.secretary_floor = this.data.secretary.floor
        labelData.secretary_desk = this.data.secretary.desk
      }
      this.axios.post("/api/courierbooking/print-barcode-label",labelData)
        .then(response => {
          // console.log(response.data)
          var printWindow = window.open('', '_blank', 'width=1000,height=600');
          printWindow.document.open();
          printWindow.document.write(response.data);
          printWindow.document.close();
          printWindow.print();
          printWindow.close();
        })
        .catch(() => {
        });
    }
  },
  props:['data']
};
</script>
<style scoped>
@media print {
  .consignment_header {
    display: none;
  }
}
.mailroom_receipt_wrapper {
  border: 10px solid #fff;
}
section.mailroom_receipt {
  width: 100%;
  /* height: 192px; */
  padding: 0 20px;
}

.mailroom_receipt .barcode_div {
  width: 65mm;
  height: auto;
  margin: 5px auto;
}
.mailroom_receipt .barcode_div img {
  width: 100%;
  /* height: auto; */
  display: block;
}
.mailroom_receipt .barcode_div p {
  text-align: center;
  font-size: 13px;
  font-weight: 300;
}
.mailroom_receipt .details_row {
  display: flex;
  padding: 1px 0;
  font-size: 15px;
  text-align: left;
}
.mailroom_receipt .details_row div:first-child {
  width: 100px;
  font-weight: 700;
  flex-shrink: 0;
}
.barcode_div img{
  height:80px !important;
  width:100%;
}
</style>